<script lang="ts">
	import { getTranslate, T } from '@tolgee/svelte';
	import PrimaryButton from './buttons/PrimaryButton.svelte';
	import posthog from 'posthog-js';
	import { getCDNUrlFromFilename } from '$lib/utils.js';

	export let headlineKey: string;
	let hasSubmitted = false;

	const t = getTranslate();
</script>

<section class="bg-white" id="contact">
	<div
		style="background-image: url({getCDNUrlFromFilename(
			'laptop-human.webp',
			1024
		)});background-image: image-set(url({getCDNUrlFromFilename(
			'laptop-human.webp',
			640
		)}) 1x,url({getCDNUrlFromFilename('laptop-human.webp', 768)}) 2x,url({getCDNUrlFromFilename(
			'laptop-human.webp',
			1024
		)}) 3x,url({getCDNUrlFromFilename('laptop-human.webp', 1280)}) 4x);"
		class="bg-no-repeat bg-cover bg-center bg-gray-600 bg-blend-multiply flex justify-center"
	>
		<div class="lg:pt-24 pt-8 mb-[17rem] max-w-2xl x-wrapper text-center">
			<h2 class="text-4xl tracking-tight font-extrabold text-white">
				{@html $t(headlineKey)}
			</h2>
		</div>
	</div>
	<div class="py-16 mx-auto -mt-72 md:-mt-[17rem] sm:py-24 x-wrapper">
		<form
			method="post"
			on:submit|preventDefault={(e) => {
				if (hasSubmitted) {
					return;
				}
				const formData = new FormData(e.target);
				posthog.capture('contact', {
					interest: formData.get('interest'),
					question: formData.get('question')
				});
				hasSubmitted = true;
			}}
			class="grid grid-cols-1 gap-8 p-6 mx-auto mb-16 max-w-screen-md bg-white rounded-lg border border-gray-200 shadow-md lg:mb-28"
		>
			<div>
				<label for="interest" class="block mb-2 text-sm font-medium text-gray-900">
					<T keyName="contact_interest_label" />
				</label>
				<input
					type="text"
					id="interest"
					name="interest"
					minlength="1"
					class="block p-3 w-full text-sm text-gray-900 bg-white rounded-lg border border-black shadow-sm focus:ring-accent-secondary focus:border-accent-secondary"
					placeholder={$t('contact_interest_placeholder')}
					required
				/>
			</div>
			<div>
				<label for="question" class="block mb-2 text-sm font-medium text-gray-900">
					<T keyName="contact_questions_label" />
				</label>
				<input
					type="text"
					id="question"
					minlength="1"
					name="question"
					class="block p-3 w-full text-sm text-gray-900 bg-white rounded-lg border border-black shadow-sm focus:ring-accent-secondary focus:border-accent-secondary"
					placeholder={$t('contact_questions_placeholder')}
					required
				/>
			</div>
			{#if hasSubmitted}
				<p class="text-green-500">
					<T keyName="contact_success_message" />
				</p>
			{:else}
				<div>
					<PrimaryButton size="l">
						<T keyName="contact_button_label" />
					</PrimaryButton>
				</div>
			{/if}
		</form>
		<div class="space-y-8 text-center flex justify-center">
			<div class="max-w-xs">
				<div
					class="flex justify-center items-center mx-auto mb-4 w-10 h-10 bg-gray-100 rounded-lg lg:h-16 lg:w-16"
				>
					<svg
						class="w-8 h-8 text-gray-600 lg:w-12 lg:h-12"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
						<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
					</svg>
				</div>
				<p class="mb-2 text-xl font-bold">
					<T keyName="contact_content_headline" />
				</p>
				<p class="mb-3 text-gray-500">
					{@html $t('contact_content_description')}
				</p>
				<a
					href={`mailto:${$t('contact_email')}`}
					class="font-semibold text-accent-secondary hover:underline"
				>
					<T keyName="contact_email" />
				</a>
			</div>
		</div>
	</div>
</section>
